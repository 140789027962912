.BAVEntryTable {
  background-color: white;
}

.BAVEntryTable .MuiDataGrid-main {
  font-size: 0.75rem;
  line-height: 1.1;
  letter-spacing: 0.008rem;
}

.BAVEntryTable .MuiDataGrid-footerContainer {
  min-height: 40px;
  max-height: 40px;
  line-height: 40px;
}

.BAVColumnHeader .MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
  margin-right: 45px;
}
.BAVColumnHeaderActive .MuiDataGrid-columnHeaderTitle {
    font-weight: bold !important;
    margin-right: 10px;
}


.BAVEntryTable .MuiDataGrid-overlayWrapper {
    min-height: 20px;
}
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
}