body {
  margin: 0;
  padding: 0;
}

.pageContainer {
  display: flex;
  flex-direction: column;
  min-height: 88vh;
}

.header {
  background-color: #f2f2f2;
  padding: 20px;
}

.main {
  flex-grow: 1;
  background-color: #ffffff;
  padding: 20px;
  overflow: auto;
}

.footer {
  background-color: #f2f2f2;
  padding: 20px;
  position: sticky;
  bottom: 0;
}
.buttonContainer {
  display: flex;
  gap: 10px; /* Abstand zwischen den Buttons */
  float: right;
}
.area {
  font-size: 20px;
  padding-left: 5px;
}

.question {
  font-size: 16px;
  padding-left: 5px;
}
.bavRadioGroup,
.bavSlider {
  padding: 16px;
}

.MuiStepIcon-root.Mui-completed {
  color: lightgrey !important;
}
.MuiStepIcon-root.Mui-active {
  color: #006e58 !important;
}
.MuiStepIcon-root {
  color: lightgrey !important;
}


.MuiButton-root.bav{
  background-color: #f2b702 !important;
  margin: 2px !important;
}
.MuiButton-root.bav:hover {
  background-color: #f59c0a !important;
}
.bavHighlight {
    background-color: rgba(255, 173, 0, 0.4); /* Hintergrundfarbe für den Text */
    padding: 5px 10px; /* Innenabstand für besseren Textabstand */
    border-radius: 5px; /* Abgerundete Ecken */
    display: inline-block;
    background-clip: padding-box; /* Hintergrundfarbe nur auf den Text anwenden */
}
