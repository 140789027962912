.BAVMainTable .MuiDataGrid-main {
  font-size: 0.75rem;
  line-height: 1.1;
  letter-spacing: 0.008rem;
}

.BAVMainTable .MuiDataGrid-footerContainer {
  min-height: 40px;
  max-height: 40px;
  line-height: 40px;
}

.BAVColumnHeader .MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}

.BAVMainTable .MuiDataGrid-overlayWrapper {
  min-height: 20px;
}

.BAVMainHeader .MuiInputBase-input {
  font-size: 0.75rem;
}
.BAVMainHeader .MuiButtonBase-root {
  font-size: 0.75rem;
}
