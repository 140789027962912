body {
}
.BavImg {
    width: 350px;
}
.BavImgFull {
    width: 1000px;
    position: absolute; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    border: solid 1px;
    
}