a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
  color: white;
}

html {
  font-size: 14px;
}
header {
  background-color: rgb(0, 110, 88);
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.navbar-nav{
    font-size: 16px;
}
.nav-link:hover {
    text-decoration: none;
    color: #f2b702!important;
    border-bottom: solid 4px;
}