header {
  background-color: rgb(0, 110, 88);
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}


.foot {
    padding-top: 5px;
    font-size: 10px;
    font-style:italic;
}