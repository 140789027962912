.btnContainer {
  max-height: 30px;
  padding-top: 3px;
  display: flex;
}
.colConfirmProfile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
}
.btnConfirmProfile {
    background-color: #2e7d32 !important;
    color: #ffffff!important;
}

.btnConfirmProfile:hover {
        background-color: #3ea43e !important;
}
    .btnConfirmProfile:disabled {
        background-color: #87ad87 !important;
    }